import React, { createRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import algoliasearch from 'algoliasearch/lite';
import { SearchBox, InstantSearch } from 'react-instantsearch-dom';
import qs from 'qs';

import {
  createTranslation,
  getActiveDocLanguage,
  getSiteMeta,
} from '../assets/js/utilities';
import { Col, Row } from '../components/grid-system';
import bp from '../assets/js/breakpoints';

import Heading from '../components/Heading';
import SearchResults from '../components/SearchResults';

const Search = ({ data, location }) => {
  // Get global data
  const layout = data.prismicLayout;

  // Get page data
  const search = data.prismicSearch;
  const { title } = search.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(search);
  const defaultMeta = getSiteMeta(layout.data);

  // Alogolia Search
  // TODO: Comment all of this
  const rootRef = createRef();

  const createURL = state => `?${qs.stringify(state)}`;

  const searchStateToUrl = (location, searchState) =>
    searchState ? `${location.pathname}${createURL(searchState)}` : '';

  const urlToSearchState = location => qs.parse(location.search.slice(1));

  const [searchState, setSearchState] = useState(urlToSearchState(location));

  function onSearchStateChange(updatedSearchState) {
    navigate(searchStateToUrl(location, { query: updatedSearchState.query }));

    setSearchState(updatedSearchState);
  }

  const algoliaClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            query: '',
          })),
        });
      }

      return algoliaClient.search(requests);
    },
  };

  const indexName =
    activeDoc.lang === 'zh-cn'
      ? 'railparkCN'
      : activeDoc.lang === 'es-es'
      ? 'railparkES'
      : 'railparkEN';

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data}>
      <Seo title={title} lang={activeDoc.locale} defaultMeta={defaultMeta} />

      <Row>
        <Col base={12} sm={10} lg={6} smOffset={1} lgOffset={3}>
          <Heading level="h1" size="l" content={title} />

          <SearchWrapper ref={rootRef}>
            <InstantSearch
              searchClient={searchClient}
              indexName={indexName}
              searchState={searchState}
              onSearchStateChange={onSearchStateChange}
              createURL={createURL}
            >
              <SearchBoxWrapper>
                <SearchBox
                  searchAsYouType={false}
                  submit={false}
                  reset={false}
                  translations={{
                    placeholder: createTranslation('Search', activeDoc.locale),
                  }}
                />

                <svg
                  width="28"
                  height="29"
                  viewBox="0 0 28 29"
                  fill="none"
                  stroke="#131313"
                  xmlns="http://www.w3.org/2000/svg"
                  className="search-icon"
                >
                  <circle cx="11.5133" cy="11.5133" r="10.5133" />
                  <line x1="18.6956" y1="19.2251" x2="27.3536" y2="27.883" />
                </svg>
              </SearchBoxWrapper>

              <SearchResults indexName={indexName} location={location} />
            </InstantSearch>
          </SearchWrapper>
        </Col>
      </Row>
    </Layout>
  );
};

export default Search;

/**
 * Styled Components
 *
 */
const SearchWrapper = styled.div`
  margin: var(--space-m) 0 var(--space-xxl);

  @media (${bp.min.sm}) {
    margin: var(--space-l) 0 var(--space-xxxl);
  }
`;

const SearchBoxWrapper = styled.div`
  margin-bottom: var(--space-l);
  position: relative;

  .ais-SearchBox-input {
    --height: 4.8rem;

    width: 100%;
    height: var(--height);
    background-color: var(--color-white);
    border-radius: var(--height);
    border: 0.1rem solid rgba(19, 19, 19, 0.2);
    padding-left: 5.6rem;
    padding-right: var(--space-m);
    font-size: clamp(2rem, 1.7647rem + 0.7353vw, 3rem);
    transition: box-shadow 150ms var(--ease-cubic-in-out);
    appearance: none;

    @media (${bp.min.sm}) {
      --height: 7.2rem;
      padding-left: 8.8rem;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(19, 19, 19, 0.2);

      &::placeholder {
        opacity: 0.25;
      }
    }

    &::placeholder {
      color: var(--color-black);
      opacity: 0.7;
      transition: opacity 200ms var(--ease-cubic-in-out);
    }
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    position: absolute;
  }

  svg {
    position: absolute;
    left: var(--space-l);
    top: 50%;
    transform: translateY(-50%);
    transition: stroke 200ms var(--ease-cubic-in-out);

    @media (${bp.max.sm}) {
      width: 2rem;
      left: var(--space-s);
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($uid: String, $language: String) {
    prismicSearch(uid: { eq: $uid }, lang: { eq: $language }) {
      data {
        title
      }
      uid
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
  }
`;
