import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { connectStateResults, Hits, Index } from 'react-instantsearch-dom';

import bp from '../assets/js/breakpoints';

import Heading from './Heading';

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;
  const query = searchResults && searchResults.query;

  return query === '' ? (
    ''
  ) : hitCount > 0 ? (
    <div className="hit-count">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : (
    'No results found'
  );
});

const PageHit = ({ hit }) => (
  <div className="hit">
    <Link to={hit.url}>
      <Heading level="h2" size="s" content={hit.title} />
      <p className="hit__url">
        {window.location.origin}
        {hit.url}
      </p>
      <p className="hit__description">{hit.meta_description}</p>
    </Link>
  </div>
);

const HitsInIndex = ({ indexName }) => (
  <Index indexName={indexName}>
    <HitCount />
    <Hits hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ indexName }) => (
  <SearchResultWrapper>
    <HitsInIndex indexName={indexName} />
  </SearchResultWrapper>
);

export default SearchResult;

/**
 * Styled Components
 *
 */
const SearchResultWrapper = styled.div`
  .hit-count {
    font-size: 1.6rem;
    margin-bottom: var(--space-s);

    @media (${bp.min.sm}) {
      margin-bottom: var(--space-m);
    }
  }

  .hit {
    margin-bottom: var(--space-m);
    padding-bottom: var(--space-m);
    border-bottom: 0.1rem solid #e1dfdf;

    @media (${bp.min.sm}) {
      margin-bottom: var(--space-m);
      padding-bottom: var(--space-l);
    }

    a {
      color: var(--color-black);

      &:hover {
        h2 {
          color: var(--color-neon-green);
        }
      }
    }

    h2 {
      transition: color 200ms var(--ease-cubic-in-out);
    }

    .hit__url {
      font-size: 1.5rem;
      opacity: 0.65;
      line-height: 1.4;
    }

    .hit__description {
      margin-top: var(--space-xxxs);
    }
  }
`;
